define("admin/routes/devices", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        groups: this.get("store").findAll("group"),
        devices: this.get("store").findAll("device"),
        electricians: this.get("store").query("user", {
          $where: {
            type: "electrician"
          }
        }),
        operationalManagers: this.get("store").query("user", {
          $where: {
            type: "operational-manager"
          }
        }),
        supportEngineers: this.get("store").query("user", {
          $where: {
            type: "support-engineer"
          }
        }),
        vehicles: this.get("store").findAll("vehicle"),
        deviceStocks: this.get("store").findAll('devices-stock')
      });
    }
  });

  _exports.default = _default;
});