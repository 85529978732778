define("admin/controllers/devices", ["exports", "moment", "admin/controllers/application", "admin/config/environment"], function (_exports, _moment, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    api: Ember.inject.service(),
    newDevice: {
      sn: null,
      group: null,
      vehicle: null,
      config: {
        sim: {},
        device: {}
      }
    },
    deleteModalProperties: {
      open: false,
      deviceObject: {
        sn: null,
        group: null,
        vehicleID: null,
        config: {
          sim: {},
          device: {}
        }
      }
    },
    deviceTypes: ["teltonika"],
    carriers: ["Telekom", "Orange", "Vodafone"],
    batch: [],
    groupObserver: Ember.observer("newDevice.group.id", function () {
      if (this.get("newDevice.group.id")) {
        this.store.query("vehicle", {
          $where: {
            company_id: this.get("newDevice.group.id")
          }
        });
      } else {
        return null;
      }
    }),
    searchText: "",
    devicesOnPage: 9,
    page: 1,
    count: Ember.computed('filteredDevicesBySearch', function () {
      var count = {
        online: 0,
        offline: 0,
        warning: 0,
        hasComm: 0,
        noComm: 0,
        hasGroup: 0,
        noGroup: 0,
        isIgnored: 0,
        isNotIgnored: 0
      };

      if (this.get("filteredDevicesBySearch") && this.get("filteredDevicesBySearch.length")) {
        this.get("filteredDevicesBySearch").forEach(function (dev) {
          if (dev.get('hasGroup')) {
            count["hasGroup"] += 1;
          } else {
            count["noGroup"] += 1;
          }

          if (dev.get('hasComment')) {
            count["hasComm"] += 1;
          } else {
            count["noComm"] += 1;
          }

          if (dev.get("status")) {
            switch (dev.get("status")) {
              case "online":
                count["online"] += 1;
                break;

              case "offline":
                count["offline"] += 1;
                break;

              case "warning":
                count["warning"] += 1;
                break;

              default:
                break;
            }
          }

          if (dev.get('ignoreStatus')) {
            count["isIgnored"] += 1;
          } else {
            count["isNotIgnored"] += 1;
          }
        });
      }

      return count;
    }),
    deviceFilters: Ember.computed("count", function () {
      var count = this.get("count");
      return [{
        value: "group-hasGroup",
        label: "Assigned - ".concat(count && count.hasGroup ? count.hasGroup : "0", " devices")
      }, {
        value: "group-noGroup",
        label: "Unassigned - ".concat(count && count.noGroup ? count.noGroup : "0", " devices")
      }, {
        value: "status-online",
        label: "Online - ".concat(count && count.online ? count.online : "0", " devices")
      }, {
        value: "status-offline",
        label: "Offline - ".concat(count && count.offline ? count.offline : "0", " devices")
      }, {
        value: "status-warning",
        label: "Warning - ".concat(count && count.warning ? count.warning : "0", " devices")
      }, {
        value: "comment-commented",
        label: "Commented - ".concat(count && count.hasComm ? count.hasComm : "0", " devices")
      }, {
        value: "comment-uncommented",
        label: "Unommented - ".concat(count && count.noComm ? count.noComm : "0", " devices")
      }, {
        value: "ignore-isIgnored",
        label: "Ignored - ".concat(count && count.isIgnored ? count.isIgnored : "0", " devices")
      }, {
        value: "ignore-isNotIgnore",
        label: "NotIgnored - ".concat(count && count.isNotIgnored ? count.isNotIgnored : "0", " devices")
      }];
    }),
    unformattedSelectedFilters: [],
    deviceSelectedFilters: Ember.computed("unformattedSelectedFilters.@each", function () {
      var deviceFilters = this.get("unformattedSelectedFilters").mapBy('value');

      if (!deviceFilters || !deviceFilters.length) {
        return null;
      }

      var group = [];
      var status = [];
      var comment = [];
      var ignore = [];
      deviceFilters.forEach(function (filter) {
        if (filter.indexOf("group-") != -1) {
          var x = filter.split("-")[1];
          group.push(x);
        }

        if (filter.indexOf("status-") != -1) {
          var _x = filter.split("-")[1];
          status.push(_x);
        }

        if (filter.indexOf("comment-") != -1) {
          var _x2 = filter.split("-")[1];
          comment.push(_x2);
        }

        if (filter.indexOf("ignore-") != -1) {
          var _x3 = filter.split("-")[1];
          ignore.push(_x3);
        }
      });
      return {
        group: group,
        status: status,
        comment: comment,
        ignore: ignore
      };
    }),
    filteredDevicesByAssign: Ember.computed("model.devices", "deviceSelectedFilters", function () {
      var devices = this.get("model.devices");
      var filters = this.get("deviceSelectedFilters");

      if (!filters) {
        return devices;
      }

      return devices.filter(function (device) {
        var validHasGroup = false;
        var validStatus = false;
        var validComment = false;
        var validIgnore = false;

        if (!filters) {
          validHasGroup = true;
          validStatus = true;
          validComment = true;
          validIgnore = true;
        } else {
          var group = filters.group;
          var status = filters.status;
          var commented = filters.comment;
          var ignored = filters.ignore;

          if (!group.length) {
            validHasGroup = true;
          } else {
            group.forEach(function (gr) {
              if (gr === "hasGroup" && device.get("hasGroup")) {
                validHasGroup = true;
              } else if (gr === "noGroup" && !device.get("hasGroup")) {
                validHasGroup = true;
              }
            });
          }

          if (!status.length) {
            validStatus = true;
          } else if (device.get("status") === "online" || device.get("status") === "offline" || device.get("status") === "warning") {
            status.forEach(function (deviceStatus) {
              if (deviceStatus === "online" && device.get("status") === "online") {
                validStatus = true;
              } else if (deviceStatus === "offline" && device.get("status") === "offline") {
                validStatus = true;
              } else if (deviceStatus === "warning" && device.get("status") === "warning") {
                validStatus = true;
              }
            });
          }

          if (!commented.length) {
            validComment = true;
          } else {
            commented.forEach(function (cm) {
              if (cm === "commented" && device.get("hasComment")) {
                validComment = true;
              } else if (cm === "uncommented" && !device.get("hasComment")) {
                validComment = true;
              }
            });
          }

          if (!ignored.length) {
            validIgnore = true;
          } else {
            ignored.forEach(function (i) {
              if (i === "isIgnored" && device.get("ignoreStatus")) {
                validIgnore = true;
              } else if (i === "isNotIgnore" && !device.get("ignoreStatus")) {
                validIgnore = true;
              }
            });
          }
        }

        if (validComment && validHasGroup && validStatus && validIgnore) {
          return true;
        } else {
          return false;
        }
      });
    }),
    filteredDevicesBySearch: Ember.computed("filteredDevicesByAssign", "searchText", function () {
      var devices = this.get("filteredDevicesByAssign");
      var searchText = this.get("searchText");

      if (!devices) {
        return [];
      }

      if (!searchText) {
        return devices;
      }

      return devices.filter(function (device) {
        if (device.get("sn") && device.get("sn").indexOf(searchText) != -1) {
          return true;
        }

        if (device.get("type") && device.get("type").indexOf(searchText) != -1) {
          return true;
        }

        if (device.get("config.device.imei") && device.get("config.device.imei").indexOf(searchText) != -1) {
          return true;
        }

        if (device.get("config.device.sim.phone_number") && device.get("config.device.sim.phone_number").indexOf(searchText) != -1) {
          return true;
        }

        if (device.get("group.name") && device.get("group.name").toLowerCase().indexOf(searchText.toLowerCase()) != -1) {
          return true;
        }

        if (device.get("vehicle.licensePlate") && device.get("vehicle.licensePlate").toLowerCase().indexOf(searchText.toLowerCase()) != -1) {
          return true;
        }

        if (device.get("vehicle.status.state") && device.get("vehicle.status.state").toLowerCase().indexOf(searchText.toLowerCase()) != -1) {
          return true;
        }

        return false;
      });
    }),
    devicesCount: Ember.computed("filteredDevicesBySearch", function () {
      return this.get("filteredDevicesBySearch.length");
    }),
    showPagination: Ember.computed("devicesCount", "devicesOnPage", function () {
      return this.get("devicesCount") > this.get("devicesOnPage");
    }),
    paginationDevices: Ember.computed("filteredDevicesBySearch", "page", "devicesOnPage", function () {
      if (!this.get("showPagination")) {
        return this.get("filteredDevicesBySearch");
      }

      var startIndex = (this.get("page") - 1) * this.get("devicesOnPage");
      var lastIndex = startIndex + this.get("devicesOnPage");
      return this.get("filteredDevicesBySearch").slice(startIndex, lastIndex);
    }),
    devicesStatus: Ember.computed("model.devices", "model.devices.@each.hasComment", "model.devices.@each.ignoreStatus", function () {
      var status = {
        online: {
          total: 0,
          uncommented: 0
        },
        offline: {
          total: 0,
          uncommented: 0
        },
        warning: {
          total: 0,
          uncommented: 0
        },
        ignore: {
          total: 0,
          uncommented: 0
        }
      };
      var devices = this.get("model.devices");

      if (!devices) {
        return 0;
      }

      devices.forEach(function (device) {
        if (device.get("ignoreStatus")) {
          status.ignore.total++;

          if (!device.get("isIgnored")) {
            status.ignore.uncommented++;
          }
        }

        if (device.get("status") === 'online') {
          status.online.total++;

          if (!device.get('hasComment')) {
            status.online.uncommented++;
          }
        } else if (device.get("status") === 'offline') {
          status.offline.total++;

          if (!device.get('hasComment')) {
            status.offline.uncommented++;
          }
        } else if (device.get("status") === 'warning') {
          status.warning.total++;

          if (!device.get('hasComment')) {
            status.warning.uncommented++;
          }
        }
      });
      return status;
    }),
    matchVehicles: function matchVehicles(vehicle, term) {
      var searchString = vehicle.get("make") + vehicle.get("model") + vehicle.get("year") + vehicle.get("vin") + vehicle.get("licensePlate");
      return searchString.toLowerCase().indexOf(term.toLowerCase());
    },
    matchElectrician: function matchElectrician(electrician, term) {
      Ember.Logger.log("Electr", electrician);
      var matchAgainst = electrician.get("firstName") + electrician.get("lastName") + electrician.get("email") + electrician.get("phoneNumber");
      return matchAgainst.toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      changedResultsOption: function changedResultsOption(option) {
        this.set("assignationFilter.selected", option.value);
        this.set('page', 1);
      },
      create: function create() {
        var device = this.get("store").createRecord("device", this.get("newDevice"));
        device.save().then(function (response) {
          Ember.Logger.log("[ COMPANIES ] [ CREATE ] [ SUCCESS ] : ", response);
          this.set("newDevice", {});
        }, function (response) {
          Ember.Logger.log("[ COMPANIES ] [ CREATE ] [ ERROR ] : ", response);
          device.destroyRecord();
        });
      },
      showDeleteModal: function showDeleteModal(deviceObject) {
        this.toggleProperty("deleteModalProperties.open");
        this.set("deleteModalProperties.deviceObject", deviceObject);
      },
      confirmDelete: function confirmDelete() {
        var _this = this;

        this.toggleProperty("deleteModalProperties.open"); // var device = this.get("store").getReference("device", this.get("deleteModalProperties.deviceObject.sn"));

        this.get("deleteModalProperties.deviceObject").destroyRecord().then(function () {
          _this.set("deleteModalProperties.deviceObject", {});
        });
      },
      testDevice: function testDevice(data) {
        data.deviceComponent.set("status", "default disabled");
        this.get('api').request("/devices/".concat(data.device_sn, "/test")).then(function (response) {
          var now = (0, _moment.default)();

          if (response.timestamp) {
            if (now.diff((0, _moment.default)(response.timestamp), "minutes") <= 30) {
              data.deviceComponent.set("status", "success");
            } else {
              data.deviceComponent.set("status", "warning");
            }

            data.deviceComponent.set("testResponse", response);
          }
        }, function (error) {
          Ember.Logger.error('Failed to test device !', error);

          if (!data.deviceComponent.get("isDestroyed")) {
            data.deviceComponent.set("status", "danger");
          }
        });
      },
      selectedCsv: function selectedCsv() {
        var file = $("#devices-file-upload")[0].files[0];
        var reader = new FileReader();

        var _this = this;

        var devices = _this.get("batch");

        devices = [];

        if (file) {
          reader.onload = function (event) {
            csvtojson({
              checkType: false
            }).fromString(event.srcElement.result).on('json', function (data) {
              Ember.Logger.log(data);

              if (data["PIN 1"] != null && data["PIN 1"].length) {
                devices.push({
                  sn: data["Device SN"],
                  config: {
                    device: {
                      imei: data["Device IMEI"],
                      sn: data["Device SN"]
                    },
                    sim: {
                      imsi: data["SIM IMSI"],
                      phone_number: data["SIM Phone"],
                      pin: data["PIN 1"],
                      puk: data["PUK 1"],
                      carrier: data["Carrier"]
                    }
                  },
                  type: data["Device Type"],
                  devicesStockID: data["Stock ID"] != null ? data["Stock ID"] : null
                });
              }
            }).on("end_parsed", function () {
              // console.log("[ BATCH DEVICES ] Devices: ", devices);
              _this.set("batch", devices);
            });
          };

          reader.readAsText(file);
        } else {
          _this.set("batch", []);
        }
      },
      uploadBatch: function uploadBatch() {
        var _this = this;

        var devices = this.get("batch");
        Ember.Logger.log("[ BATCH DEVICES ] Devices: ", devices);
        devices.forEach(function (deviceData) {
          var device = _this.get("store").createRecord("device", deviceData);

          device.save().then(function () {// Ember.Logger.log("[ DEVICE BATCH ] Created: ", response);
          }, function () {// Ember.Logger.error("[ DEVICE BATCH ] Error: ", error);
          });
        });
        this.set("batch", []);
        $("devices-file-upload").val('');
      },
      update: function update(device, component) {
        Ember.Logger.log("Test", device);
        component.toggleProperty("edit");
        device.save().then(function (response) {
          Ember.Logger.log(response);
        }, function (error) {
          Ember.Logger.log(error);
        });
      },
      unassignGroup: function unassignGroup() {
        this.set("newDevice.group", null);
        this.set("newDevice.vehicle", null);
      },
      assignGroup: function assignGroup(group) {
        this.set("newDevice.group", group);
      },
      assignVehicle: function assignVehicle(vehicle) {
        this.set("newDevice.vehicle", vehicle);
      },
      findCompanyVehicles: function findCompanyVehicles(group) {
        this.store.query("vehicle", {
          $where: {
            company_id: group.get("companyID")
          }
        });
      },
      selectPage: function selectPage(pageNumber) {
        if (!pageNumber) {
          return false;
        }

        this.set("page", pageNumber);
      },
      removeSelectedFilter: function removeSelectedFilter(val) {
        var index = this.get('unformattedSelectedFilters').mapBy('value').indexOf(val);

        if (index > -1) {
          this.get('unformattedSelectedFilters').splice(index, 1);
          this.notifyPropertyChange('unformattedSelectedFilters');
        }
      }
    }
  });

  _exports.default = _default;
});