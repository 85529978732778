define("admin/controllers/pipeline", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service('api'),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    localSettings: Ember.inject.service('local-settings'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('deleteOfferModal', {
        open: false,
        offerToDelete: {}
      });
      this.set('newCompany', null);
    },
    resetNewCompanyForm: function resetNewCompanyForm() {
      this.set('newCompany', {
        name: null,
        details: {
          CUI: null,
          J: null,
          address: null,
          contractDate: null,
          pricePrDevice: null,
          subscriptionFee: null,
          adminPhone: null,
          administrator: {
            fullName: null,
            phone: null,
            email: null
          },
          fleetManager: {
            fullName: null,
            phone: null,
            email: null
          },
          financeManager: {
            fullName: null,
            phone: null,
            email: null
          },
          correspondence: null
        },
        offers: [],
        archived: false
      });
    },
    existingCompanyErrorModal: {
      open: false,
      salesAgent: null,
      message: null
    },
    uploadProgress: null,
    search: "",
    itemsOnPage: 15,
    page: 1,
    companiesCount: Ember.computed("potentialCustomersSearchResults.@each", function () {
      return this.get("potentialCustomersSearchResults.length");
    }),
    showPagination: Ember.computed("companiesCount", "itemsOnPage", function () {
      return this.get("companiesCount") > this.get("itemsOnPage");
    }),
    potentialCustomersSearchResults: Ember.computed('model.potentialCustomers.@each', 'search', function () {
      var potentialCustomers = this.get("model.potentialCustomers");
      var search = this.get("search").toLowerCase();

      if (search.length) {
        return potentialCustomers.filter(function (potentialCustomer) {
          return potentialCustomer.get('name') ? potentialCustomer.get('name').toLowerCase().includes(search) : false;
        });
      } else {
        return potentialCustomers;
      }
    }),
    paginatedPotentialCustomers: Ember.computed('potentialCustomersSearchResults.@each', 'page', 'itemsOnPage', function () {
      if (!this.get("showPagination")) {
        return this.get("potentialCustomersSearchResults");
      }

      var startIndex = (this.get("page") - 1) * this.get("itemsOnPage");
      var lastIndex = startIndex + this.get("itemsOnPage");
      return this.get("potentialCustomersSearchResults").slice(startIndex, lastIndex);
    }),
    actions: {
      addCompany: function addCompany() {
        // this.resetNewCompanyForm();
        if (this.get('newCompany.isNew')) {
          return 0;
        }

        var newCompany = this.get('store').createRecord('potentialCustomer'); // let newCompanyStore = this.get("store").createRecord("group");

        this.set('newCompany', newCompany);
      },
      addProspect: function addProspect() {
        if (this.get('newProspect.isNew')) {
          return 0;
        }

        var newProspect = this.get('store').createRecord('potentialCustomer');
        newProspect.set('isProspect', true);
        this.set('newProspect', newProspect);
      },
      addNewCompanyCallback: function addNewCompanyCallback(company) {
        // For some unknown weird reason this needs to be done. Otherwise the user would have to refresh the page manually
        this.set('model.potentialCustomers', this.get('store').peekAll('potential-customer'));
        this.notifyPropertyChange('model.potentialCustomers');
      },
      addNewCompanyErrorCallback: function addNewCompanyErrorCallback(error) {
        Ember.Logger.log('Error: ', error);
        var message = null;
        var salesAgent = error.meta;
        var currentUser = this.get('currentUser.user');
        var sameAgent = salesAgent && salesAgent.id === currentUser.id ? true : false;

        switch (error.title) {
          case 'existing_group':
            if (sameAgent) {
              message = 'You already have a company with this CUI in your portfolio.';
            } else {
              message = 'A company with this CUI is already registered in the portfolio of another user.';
            }

            break;

          case 'existing_potential_customer':
            if (sameAgent) {
              message = 'You already have a company with this CUI in your pipeline.';
            } else {
              message = 'A company with this CUI is already in the pipeline of another sales representative.';
            }

            break;

          default:
            message = 'Existing company. ';
            break;
        }

        if (error.meta && !sameAgent) {
          message += ' You may be able to contact the sales representative who registered this company using the following contact details: ';
          this.set('existingCompanyErrorModal.salesAgent', error.meta);
        }

        this.set('existingCompanyErrorModal.message', message);
        this.set('existingCompanyErrorModal.open', true);
      },
      downloadOffer: function downloadOffer(offerId) {
        var token = this.get('session.session.authenticated.idToken');
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/download/").concat(offerId), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));
        this.get('store').findRecord('offer', offerId).then(function (offer) {
          var companyName = Ember.get(offer, 'company.name');

          req.onload = function (event) {
            var blob = req.response;
            Ember.Logger.log('File size: ', blob.size);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Tracknamic offer for ".concat(companyName, " - ") + new Date().toGMTString() + ".pdf";
            link.click();
          };

          req.send();
        });
      },
      downloadProforma: function downloadProforma(offerID, callback) {
        var token = this.get('session.session.authenticated.idToken');
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/proforma/").concat(offerID), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf'); // req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');

        req.setRequestHeader('Authorization', "Bearer ".concat(token));

        req.onload = function (event) {
          var blob = req.response;
          console.log('File size: ', blob.size);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Proforma-Tracknamic.pdf";
          link.click();

          if (callback != null) {
            callback();
          }
        };

        req.send();
      },
      downloadDocument: function downloadDocument(company, documentType) {
        var token = this.get('session.session.authenticated.idToken');
        var endpoint = 'contract';
        var extension = /[^.]+$/.exec(company.get('details.contractFileName'))[0];

        if (documentType == 'demo') {
          endpoint = 'downloadSignedDemoRequest';
          extension = /[^.]+$/.exec(company.get('details.demoRequestFileName'))[0];
        }

        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/potentialCustomers/").concat(company.get('id'), "/").concat(endpoint), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));

        req.onload = function (event) {
          var blob = req.response;
          Ember.Logger.log('File size: ', blob.size);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          if (documentType == 'demo') {
            link.download = "".concat(company.get('name'), " Demo Custody Request.").concat(extension);
          } else {
            link.download = "Tracknamic-Contract.".concat(extension);
          }

          link.click();
        };

        req.send();
      },
      uploadDocument: function uploadDocument(company, file, documentType, onComplete) {
        var _this = this;

        var endpoint = 'contract';

        if (documentType == 'demo-custody-request') {
          endpoint = 'uploadSignedDemoRequest';
        }

        if (file) {
          var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/potentialCustomers/").concat(company.get('id'), "/").concat(endpoint);
          var idToken = this.get('session.session.authenticated.idToken');
          var fd = new FormData();

          if (documentType == 'demo-custody-request') {
            fd.append('signed-demo-request', file);
          } else {
            fd.append('contract', file);
          }

          var xhr = new XMLHttpRequest();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
          var platform = this.get('localSettings').get('settings.selectedPlatform');
          xhr.setRequestHeader('trk-platform', platform.value);

          xhr.onreadystatechange = function (oEvent) {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                Ember.Logger.log("Upload Response: ", xhr.responseText);
              } else {
                Ember.Logger.log("Upload Error:", xhr.statusText);

                _this.set('uploadProgress', null);
              }
            }
          };

          xhr.upload.onprogress = function (pe) {
            if (pe.lengthComputable) {
              var percentComplete = parseInt(pe.loaded / pe.total * 100);

              _this.set("uploadProgress", percentComplete === 100 ? null : percentComplete);
            }
          };

          xhr.onloadend = function (pe) {
            try {
              var response = JSON.parse(pe.target.responseText);

              _this.set("completedRequest", true);

              _this.set('uploadProgress', null);

              _this.set("response", response);

              if (documentType == 'demo-custody-request') {
                company.set('details.demoRequestETag', response.demoRequestETag);
                company.set('details.demoRequestFileName', response.demoRequestFileName);
              } else {
                company.set('details.contractETag', response.contractETag);
                company.set('details.contractFileName', response.contractFileName);
              }

              if (onComplete) {
                onComplete(response);
              }
            } catch (e) {
              _this.set("completedRequest", true); // this.set("err", true)

            }
          };

          xhr.send(fd);
        }
      },
      confirmDeleteOffer: function confirmDeleteOffer() {
        this.get("deleteOfferModal.offerToDelete").destroyRecord();
        this.set("deleteOfferModal.offerToDelete", null);
        this.set("deleteOfferModal.open", false);
        var callback = this.get('deleteOfferModal.callback');

        if (callback) {
          callback();
          this.set('deleteOfferModal.callback', null);
        }
      },
      deleteOffer: function deleteOffer(offer, callback) {
        this.set("deleteOfferModal.open", true);
        this.set("deleteOfferModal.offerToDelete", offer);

        if (callback) {
          this.set('deleteOfferModal.callback', callback);
        }
      },
      hidingModal: function hidingModal() {
        this.set("deleteOfferModal.offerToDelete", null);
        this.set("deleteOfferModal.open", false);
      },
      cancelDeleteOffer: function cancelDeleteOffer() {
        this.set("deleteOfferModal.offerToDelete", null);
        this.set("deleteOfferModal.open", false);
      },
      selectPage: function selectPage(pageNumber) {
        if (!pageNumber) {
          return false;
        }

        this.set("page", pageNumber);
      },
      convertToCustomer: function convertToCustomer(potentialCustomer, callback) {
        var _this2 = this;

        this.get('api').request("/potentialCustomers/".concat(potentialCustomer.get('id'), "/convert"), {
          method: 'post'
        }).then(function (response) {
          var group = _this2.get('store').createRecord('group', response.group);

          if (callback) {
            callback(group);
          }

          _this2.get('store').unloadRecord(potentialCustomer);
        }).catch(function (error) {
          Ember.Logger.error('THERE WAS AN ERROR:', error);
        });
      },
      clearSearchField: function clearSearchField() {
        this.set('search', '');
      },
      endDemo: function endDemo() {},
      archiveDemo: function archiveDemo() {}
    }
  });

  _exports.default = _default;
});