define("admin/controllers/portfolio", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service('api'),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    localSettings: Ember.inject.service('local-settings'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('deleteOfferModal', {
        open: false,
        offerToDelete: {}
      });
      this.set('selectedSalesAgent', null);
      this.resetEndDemo();
    },
    uploadProgress: null,
    search: "",
    itemsOnPage: 15,
    page: 1,
    sortedGroups: Ember.computed.sort('model.groups', 'sortDefinition'),
    sortDefinition: ['id:asc'],
    expiredInvoices: Ember.computed('model.groups', function () {
      var debts = {};
      this.get('model.groups').forEach(function (group) {
        if (group.get('debts')) {
          group.get('debts').forEach(function (debt) {
            if (debts[debt.currency] == null) {
              debts[debt.currency] = {
                currency: debt.currency,
                amount: 0
              };
            }

            debts[debt.currency].amount += debt.amount;
          });
        }
      });
    }),
    searchResult: Ember.computed('sortedGroups', 'search', 'selectedSalesAgent', function () {
      var groups = this.get("sortedGroups");
      var search = this.get("search");
      var salesAgent = this.get('selectedSalesAgent');

      if (search.length) {
        search = search.toLowerCase();
        return groups.filter(function (group) {
          if (salesAgent && salesAgent.get('id') != group.get('salesAgent.id')) {
            return false;
          }

          return group.get('name') ? group.get('name').toLowerCase().includes(search) || group.get('id').includes(search) : false;
        });
      } else {
        if (!salesAgent) {
          return groups;
        } else {
          return groups.filter(function (group) {
            return salesAgent.get('id') == group.get('salesAgent.id');
          });
        }
      }
    }),
    companiesCount: Ember.computed("searchResult", function () {
      return this.get("searchResult.length");
    }),
    showPagination: Ember.computed("companiesCount", "itemsOnPage", function () {
      return this.get("companiesCount") > this.get("itemsOnPage");
    }),
    paginationGroups: Ember.computed("searchResult", "page", "itemsOnPage", function () {
      if (!this.get("showPagination")) {
        return this.get("searchResult");
      }

      var startIndex = (this.get("page") - 1) * this.get("itemsOnPage");
      var lastIndex = startIndex + this.get("itemsOnPage");
      return this.get("searchResult").slice(startIndex, lastIndex);
    }),
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    isSuperUser: Ember.computed("currentUser.isSuperAdmin", 'currentUser.isFinancialOfficer', function () {
      return this.get('currentUser.isSuperAdmin') || this.get('currentUser.isFinancialOfficer');
    }),
    userIsDeveloper: Ember.computed('currentUser.isSuperAdmin', function () {
      return this.get('currentUser.user.email') === 'agj@valgbar.com';
    }),
    endDemoModal: null,
    resetEndDemo: function resetEndDemo() {
      this.set('endDemoModal', Ember.Object.create({
        offer: null,
        open: false,
        suggestedDocumentType: null
      }));
    },
    actions: {
      addCompany: function addCompany() {
        if (this.get('newCompany.isNew')) {
          return 0;
        }

        var newCompany = this.get('store').createRecord('potentialCustomer'); // let newCompanyStore = this.get("store").createRecord("group");

        this.set('newCompany', newCompany);
      },
      filterBySalesAgent: function filterBySalesAgent(salesAgent) {
        this.set('selectedSalesAgent', salesAgent);
      },
      downloadOffer: function downloadOffer(offerId) {
        var token = this.get('session.session.authenticated.idToken');
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/download/").concat(offerId), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));
        this.get('store').findRecord('offer', offerId).then(function (offer) {
          var companyName = Ember.get(offer, 'company.name');

          req.onload = function (event) {
            var blob = req.response;
            Ember.Logger.log('File size: ', blob.size);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Tracknamic offer for ".concat(companyName, " - ") + new Date().toGMTString() + ".pdf";
            link.click();
          };

          req.send();
        });
      },
      loadedInvoiceStatusesFile: function loadedInvoiceStatusesFile(element) {
        var file = element.target.files[0];
        Ember.Logger.log('New file', file);

        if (file) {
          this.set("newFileSelected", true);
          this.set("showConfirmationButton", false);
          this.set("invoice_statuses_file", file);
        } else {
          this.$('invoice-statuses-file').val(null);
          this.set("newFileSelected", false);
          this.set("invoice_statuses_file", null);
        }
      },
      updateInvoiceStatuses: function updateInvoiceStatuses() {
        var _this = this;

        var file = this.get('invoice_statuses_file');

        if (file) {
          var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/invoices/uploadStatuses");
          var idToken = this.get('session.session.authenticated.idToken');
          var fd = new FormData();
          fd.append('invoice_statuses_file', file);
          var xhr = new XMLHttpRequest();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
          var platform = this.get('localSettings').get('settings.selectedPlatform');
          xhr.setRequestHeader('trk-platform', platform.value);

          xhr.onreadystatechange = function (oEvent) {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                Ember.Logger.log("Upload Response: ", xhr.responseText);
              } else {
                Ember.Logger.log("Upload Error:", xhr.statusText);

                _this.set('uploadProgress', null);
              }
            }
          };

          xhr.upload.onprogress = function (pe) {
            if (pe.lengthComputable) {
              var percentComplete = parseInt(pe.loaded / pe.total * 100);

              _this.set("uploadProgress", percentComplete === 100 ? null : percentComplete);
            }
          };

          xhr.onloadend = function (pe) {
            try {
              var response = JSON.parse(pe.target.responseText);

              _this.set("updatedInvoiceStatuses", true);

              _this.set('uploadInvoiceStatusesProgress', null);

              _this.set("responseInvoiceStatuses", response);

              if (onComplete) {
                onComplete(response);
              }
            } catch (e) {
              _this.set("completedRequest", true); // this.set("err", true)

            }
          };

          xhr.send(fd);
        }
      },
      downloadDocument: function downloadDocument(company, documentType) {
        var token = this.get('session.session.authenticated.idToken');
        var endpoint = 'contract';
        var extension = /[^.]+$/.exec(company.get('details.contractFileName'))[0];

        if (documentType == 'demo') {
          endpoint = 'downloadSignedDemoRequest';
          extension = /[^.]+$/.exec(company.get('details.demoRequestFileName'))[0];
        }

        Ember.Logger.log(endpoint, documentType);
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/groups/").concat(company.get('id'), "/").concat(endpoint), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', '*/*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));
        var platform = this.get('localSettings').get('settings.selectedPlatform');
        req.setRequestHeader('trk-platform', platform.value);

        req.onload = function (event) {
          var blob = req.response;
          console.log('File size: ', blob.size);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          if (documentType == 'demo') {
            link.download = "".concat(company.get('name'), " Demo Custody Request.").concat(extension);
          } else {
            link.download = "Tracknamic-Contract.".concat(extension);
          }

          link.click();
        };

        req.send();
      },
      downloadProforma: function downloadProforma(offerID) {
        var token = this.get('session.session.authenticated.idToken');
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/proforma/").concat(offerID), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));
        var platform = this.get('localSettings').get('settings.selectedPlatform');
        req.setRequestHeader('trk-platform', platform.value);

        req.onload = function (event) {
          var blob = req.response;
          console.log('File size: ', blob.size);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Proforma-Tracknamic.pdf";
          link.click();
        };

        req.send();
      },
      uploadDocument: function uploadDocument(company, file, documentType, onComplete) {
        var _this = this;

        var endpoint = 'contract';

        if (documentType == 'demo-custody-request') {
          endpoint = 'uploadSignedDemoRequest';
        }

        if (file) {
          var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/groups/").concat(company.get('id'), "/").concat(endpoint);
          var idToken = this.get('session.session.authenticated.idToken');
          var fd = new FormData();

          if (documentType == 'demo-custody-request') {
            fd.append('signed-demo-request', file);
          } else {
            fd.append('contract', file);
          }

          var xhr = new XMLHttpRequest();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
          var platform = this.get('localSettings').get('settings.selectedPlatform');
          xhr.setRequestHeader('trk-platform', platform.value);

          xhr.onreadystatechange = function (oEvent) {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                Ember.Logger.log("Upload Response: ", xhr.responseText);
              } else {
                Ember.Logger.log("Upload Error:", xhr.statusText);

                _this.set('uploadProgress', null);
              }
            }
          };

          xhr.upload.onprogress = function (pe) {
            if (pe.lengthComputable) {
              var percentComplete = parseInt(pe.loaded / pe.total * 100);

              _this.set("uploadProgress", percentComplete === 100 ? null : percentComplete);
            }
          };

          xhr.onloadend = function (pe) {
            try {
              var response = JSON.parse(pe.target.responseText);

              _this.set("completedRequest", true);

              _this.set('uploadProgress', null);

              _this.set("response", response);

              if (documentType == 'demo-custody-request') {
                company.set('details.demoRequestETag', response.demoRequestETag);
                company.set('details.demoRequestFileName', response.demoRequestFileName);
              } else {
                company.set('details.contractETag', response.contractETag);
                company.set('details.contractFileName', response.contractFileName);
              }

              if (onComplete) {
                onComplete(response);
              }
            } catch (e) {
              _this.set("completedRequest", true);
            }
          };

          xhr.send(fd);
        }
      },
      confirmDeleteOffer: function confirmDeleteOffer() {
        var _this2 = this;

        if (this.get('deleteOfferModal.offerToDelete.invoiceID')) {
          return this.get('deleteOfferModal.offerToDelete.invoice').then(function (invoice) {
            return invoice.destroyRecord().then(function () {
              _this2.get("deleteOfferModal.offerToDelete").destroyRecord();

              _this2.set("deleteOfferModal.offerToDelete", null);

              _this2.set("deleteOfferModal.open", false);
            });
          });
        } else {
          this.get("deleteOfferModal.offerToDelete").destroyRecord();
          this.set("deleteOfferModal.offerToDelete", null);
          this.set("deleteOfferModal.open", false);
        }

        var callback = this.get('deleteOfferModal.callback');

        if (callback) {
          callback();
          this.set('deleteOfferModal.callback', null);
        }
      },
      deleteOffer: function deleteOffer(offer, callback) {
        this.set("deleteOfferModal.open", true);
        this.set("deleteOfferModal.offerToDelete", offer);

        if (callback) {
          this.set('deleteOfferModal.callback', callback);
        }
      },
      hidingModal: function hidingModal() {
        this.set("deleteOfferModal.offerToDelete", null);
        this.set("deleteOfferModal.open", false);
      },
      cancelDeleteOffer: function cancelDeleteOffer() {
        this.set("deleteOfferModal.offerToDelete", null);
        this.set("deleteOfferModal.open", false);
      },
      selectPage: function selectPage(pageNumber) {
        if (!pageNumber) {
          return false;
        }

        this.set("page", pageNumber);
      },
      clearSalesAgentFilter: function clearSalesAgentFilter() {
        this.set('selectedSalesAgent', null);
      },
      migrateInvoices: function migrateInvoices() {
        this.get('api').request('invoices/migrate', {}).then(function (result) {
          Ember.Logger.log('Migrated invoices', result);
        }).catch(function (error) {
          Ember.Logger.error('Invoice migration failed!', error);
        });
      },
      migrateSubscriptions: function migrateSubscriptions() {
        this.get('api').request('groupSubscriptions/migrate', {}).then(function (result) {
          Ember.Logger.log('Migrated subscriptions', result);
        }).catch(function (error) {
          Ember.Logger.error('Subscriptions migration failed!', error);
        });
      },
      migrateDocumentsRegistry: function migrateDocumentsRegistry() {
        this.get('api').request('documentsRegistry/migrate', {}).then(function (result) {
          Ember.Logger.log('Migrated Documents Registry', result);
        }).catch(function (error) {
          Ember.Logger.error('Documents Registry migration failed!', error);
        });
      },
      normalizeDetails: function normalizeDetails() {
        this.get('api').request('groups/normalizeDetails', {}).then(function (result) {
          Ember.Logger.log('Normalized group details:', result);
        }).catch(function (error) {
          Ember.Logger.error('Normalization for group detailes failed!', error);
        });
      },
      clearSearchField: function clearSearchField() {
        this.set('search', '');
      },
      endDemo: function endDemo(offer) {
        var company = offer.get('company');
        var order = offer.get('order');
        var modal = this.get('endDemoModal');
        modal.set('offer', offer);
        modal.set('order', order);
        modal.set('open', true);
        var contractOffer = company.get('offers').find(function (offer) {
          return offer.get('documentType') === 'contract';
        });

        if (contractOffer) {
          modal.set('suggestedDocumentType', 'addendum');
        } else {
          modal.set('suggestedDocumentType', 'contract');
        }
      },
      convertDemo: function convertDemo() {
        var _this3 = this;

        var offer = this.get('endDemoModal.offer');
        var documentType = this.get('endDemoModal.suggestedDocumentType');
        var company = this.get('store').peekRecord('group', offer.get('company.id'));
        this.get('store').createRecord('offer', {
          demo: {
            startDate: null,
            endDate: null
          },
          company: offer.get('company'),
          companyType: 'group',
          details: offer.get('details').map(function (detail) {
            return detail.copy();
          }),
          oldDetails: offer.get('oldDetails').map(function (detail) {
            return detail.copy();
          }),
          documentFileName: null,
          documentNumber: null,
          documentType: this.get('endDemoModal.suggestedDocumentType'),
          documentDate: moment().toDate(),
          salesAgentID: offer.get('salesAgentID')
        }).save().then(function (newOffer) {
          offer.set('archived', true);
          company.get('offers').pushObject(newOffer);
          return offer.save();
        }).then(function (oldOffer) {
          Ember.Logger.log("Successfuly converted demo offer to ".concat(documentType, " offer."), oldOffer);
          Ember.Logger.log('FOUND THE FUCKING COMPANY', company);
          company.set('isProspect', false);
          return company.save();
        }).then(function (updated) {
          Ember.Logger.log('Successfully promoted demo to customer');

          _this3.resetEndDemo();
        }).catch(function (error) {
          Ember.Logger.log("Error while attempting to convert demo to ".concat(documentType, "."), error);
        });
      },
      archiveDemo: function archiveDemo() {
        var _this4 = this;

        var offer = this.get('endDemoModal.offer');
        offer.set('archived', true);
        offer.save().then(function () {
          Ember.Logger.log('Successfully ended demo');

          _this4.resetEndDemo();
        }).catch(function (error) {
          Ember.Logger.error('Error while attempting to archive demo offer: ', error);
        });
      },
      cancelEndDemo: function cancelEndDemo() {
        this.resetEndDemo();
      }
    }
  });

  _exports.default = _default;
});