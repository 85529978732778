define("admin/components/module-picker/component", ["exports", "ember-simple-tree/utils/tree"], function (_exports, _tree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tree: null,
    recursiveGroupCheck: function recursiveGroupCheck(items) {
      var _this = this;

      items.forEach(function (node) {
        if (node.children == null || node.children.length == 0) {
          return;
        }

        _this.recursiveGroupCheck(node.children);

        var isChecked = false;
        var isIndeterminate = false;

        if (node.children.every(function (x) {
          return x.isChecked;
        })) {
          isChecked = true;
        } else if (node.children.some(function (x) {
          return x.isChecked || x.isIndeterminate;
        })) {
          isIndeterminate = true;
        }

        Ember.setProperties(node, {
          isChecked: isChecked,
          isIndeterminate: isIndeterminate
        });
      });
    },
    selectedChanged: Ember.observer('selected.@each', function () {
      var _this2 = this;

      var tree = this.get('tree');
      var selected = this.get('selected');

      if (!Ember.isArray(selected)) {
        return;
      }

      var items = _tree.default.getDescendents(tree);

      Ember.run.scheduleOnce('render', function () {
        items.forEach(function (node) {
          if (node.children != null && node.children.length > 0) {
            return;
          }

          var includes = selected.includes(node.id);
          Ember.set(node, 'isChecked', includes);
        });

        _this2.recursiveGroupCheck(items);
      });
    }).on('init'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tree', [{
        id: 'g-old',
        name: 'Old',
        isVisible: true,
        children: [{
          id: 'g-reports',
          name: 'Reports',
          isVisible: true,
          children: [{
            id: 'report-1',
            name: 'FAZ',
            isVisible: true,
            children: []
          }, {
            id: 'report-2',
            name: 'Routes',
            isVisible: true,
            children: []
          }, {
            id: 'report-3',
            name: 'Stationary',
            isVisible: true,
            children: []
          }, {
            id: 'report-5',
            name: 'After hours',
            isVisible: true,
            children: []
          }, {
            id: 'report-6',
            name: 'POI activity',
            isVisible: true,
            children: []
          }, {
            id: 'report-11',
            name: 'Fuel level',
            isVisible: true,
            children: []
          }, {
            id: 'report-12',
            name: 'Engine',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-management',
          name: 'Management',
          isVisible: true,
          children: [{
            id: 'vehicles',
            name: 'Vehicles',
            isVisible: true,
            children: []
          }, {
            id: 'users',
            name: 'Users',
            isVisible: true,
            children: []
          }, {
            id: 'subgroups',
            name: 'Subgroups',
            isVisible: true,
            children: []
          }, {
            id: 'geofences',
            name: 'Geofences',
            isVisible: true,
            children: []
          }, {
            id: 'timetables',
            name: 'Timetables',
            isVisible: true,
            children: []
          }, {
            id: 'integrations',
            name: 'Integrations',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-map',
          name: 'Map',
          isVisible: true,
          children: [{
            id: 'map',
            name: 'Map',
            isVisible: true,
            children: []
          }, {
            id: 'map-routes',
            name: 'Routes',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-notifications',
          name: 'Notifications',
          isVisible: true,
          children: [{
            id: 'notifications',
            name: 'Notifications',
            isVisible: true,
            children: []
          }, {
            id: 'email',
            name: 'Email',
            isVisible: true,
            children: []
          }, {
            id: 'sms',
            name: 'SMS',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-widgets',
          name: 'Widgets',
          isVisible: true,
          children: [{
            id: 'widget-activity',
            name: 'Activity',
            isVisible: true,
            children: []
          }, {
            id: 'widget-live',
            name: 'Live status',
            isVisible: true,
            children: []
          }, {
            id: 'widget-alerts',
            name: 'Alerts',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-sensors',
          name: 'Sensors',
          isVisible: true,
          children: [{
            id: 'can',
            name: 'CAN',
            isVisible: true,
            children: []
          }, {
            id: 'sensor-fuel',
            name: 'Fuel level',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-speed-limit',
          name: 'Speed limit',
          isVisible: true,
          children: [{
            id: 'speed-limit',
            name: 'Setting',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-optional',
          name: 'Optional',
          isVisible: true,
          children: [{
            id: 'g-sensors',
            name: 'Sensors',
            isVisible: true,
            children: [{
              id: 'sensor-door',
              name: 'Door',
              isVisible: true,
              children: []
            }, {
              id: 'mobileye',
              name: 'MobilEye',
              isVisible: true,
              children: []
            }, {
              id: 'freezer',
              name: 'Freezer',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-extras',
            name: 'Extras',
            isVisible: true,
            children: [{
              id: 'taxi',
              name: 'Taxi',
              isVisible: true,
              children: []
            }, {
              id: 'seal',
              name: 'Seal',
              isVisible: true,
              children: []
            }, {
              id: 'relay',
              name: 'Relay',
              isVisible: true,
              children: []
            }, {
              id: 'icons-custom',
              name: 'Custom icons',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-speed-limit',
            name: 'Speed limit',
            isVisible: true,
            children: [{
              id: 'speed-road',
              name: 'Road',
              isVisible: true,
              children: []
            }]
          }]
        }, {
          id: 'cng',
          name: 'CNG',
          isVisible: true,
          children: []
        }]
      }, {
        id: 'g-basic',
        name: 'Basic',
        isVisible: true,
        children: [{
          id: 'g-reports',
          name: 'Reports',
          isVisible: true,
          children: [{
            id: 'report-1',
            name: 'FAZ',
            isVisible: true,
            children: []
          }, {
            id: 'report-2',
            name: 'Routes',
            isVisible: true,
            children: []
          }, {
            id: 'report-3',
            name: 'Stationary',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-management',
          name: 'Management',
          isVisible: true,
          children: [{
            id: 'vehicles',
            name: 'Vehicles',
            isVisible: true,
            children: []
          }, {
            id: 'users',
            name: 'Users',
            isVisible: true,
            children: []
          }, {
            id: 'subgroups',
            name: 'Subgroups',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-map',
          name: 'Map',
          isVisible: true,
          children: [{
            id: 'map',
            name: 'Map',
            isVisible: true,
            children: []
          }, {
            id: 'map-routes',
            name: 'Routes',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-notifications',
          name: 'Notifications',
          isVisible: true,
          children: [{
            id: 'notifications',
            name: 'Notifications',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-widgets',
          name: 'Widgets',
          isVisible: true,
          children: [{
            id: 'widget-activity',
            name: 'Activity',
            isVisible: true,
            children: []
          }, {
            id: 'widget-alerts',
            name: 'Alerts',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'cng',
          name: 'CNG',
          isVisible: true,
          children: []
        }]
      }, {
        id: 'g-pro',
        name: 'Pro',
        isVisible: true,
        children: [{
          id: 'g-reports',
          name: 'Reports',
          isVisible: true,
          children: [{
            id: 'report-1',
            name: 'FAZ',
            isVisible: true,
            children: []
          }, {
            id: 'report-2',
            name: 'Routes',
            isVisible: true,
            children: []
          }, {
            id: 'report-3',
            name: 'Stationary',
            isVisible: true,
            children: []
          }, {
            id: 'report-5',
            name: 'After hours',
            isVisible: true,
            children: []
          }, {
            id: 'report-6',
            name: 'POI activity',
            isVisible: true,
            children: []
          }, {
            id: 'report-11',
            name: 'Fuel level',
            isVisible: true,
            children: []
          }, {
            id: 'report-12',
            name: 'Engine',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-management',
          name: 'Management',
          isVisible: true,
          children: [{
            id: 'vehicles',
            name: 'Vehicles',
            isVisible: true,
            children: []
          }, {
            id: 'users',
            name: 'Users',
            isVisible: true,
            children: []
          }, {
            id: 'subgroups',
            name: 'Subgroups',
            isVisible: true,
            children: []
          }, {
            id: 'geofences',
            name: 'Geofences',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-map',
          name: 'Map',
          isVisible: true,
          children: [{
            id: 'map',
            name: 'Map',
            isVisible: true,
            children: []
          }, {
            id: 'map-routes',
            name: 'Routes',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-notifications',
          name: 'Notifications',
          isVisible: true,
          children: [{
            id: 'notifications',
            name: 'Notifications',
            isVisible: true,
            children: []
          }, {
            id: 'email',
            name: 'Email',
            isVisible: true,
            children: []
          }, {
            id: 'sms',
            name: 'SMS',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-widgets',
          name: 'Widgets',
          isVisible: true,
          children: [{
            id: 'widget-activity',
            name: 'Activity',
            isVisible: true,
            children: []
          }, {
            id: 'widget-live',
            name: 'Live status',
            isVisible: true,
            children: []
          }, {
            id: 'widget-alerts',
            name: 'Alerts',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-speed-limit',
          name: 'Speed limit',
          isVisible: true,
          children: [{
            id: 'speed-limit',
            name: 'Setting',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-optional',
          name: 'Optional',
          isVisible: true,
          children: [{
            id: 'g-reports',
            name: 'Reports',
            isVisible: true,
            children: [{
              id: 'report-14',
              name: 'Countries',
              isVisible: true,
              children: []
            }, {
              id: 'report-16',
              name: 'Costs',
              isVisible: true,
              children: []
            }, {
              id: 'report-17',
              name: 'Fleet summary',
              isVisible: true,
              children: []
            }, {
              id: 'report-33',
              name: 'Driver routes',
              isVisible: true,
              children: []
            }, {
              id: 'report-100',
              name: 'Detailed stops',
              isVisible: true,
              children: []
            }, {
              id: 'report-102',
              name: 'Detailed route',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-management',
            name: 'Management',
            isVisible: true,
            children: [{
              id: 'timetables',
              name: 'Timetables',
              isVisible: true,
              children: []
            }, {
              id: 'integrations',
              name: 'Integrations',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-sensors',
            name: 'Sensors',
            isVisible: true,
            children: [{
              id: 'can',
              name: 'CAN',
              isVisible: true,
              children: []
            }, {
              id: 'sensor-fuel',
              name: 'Fuel level',
              isVisible: true,
              children: []
            }, {
              id: 'sensor-door',
              name: 'Door',
              isVisible: true,
              children: []
            }, {
              id: 'mobileye',
              name: 'MobilEye',
              isVisible: true,
              children: []
            }, {
              id: 'freezer',
              name: 'Freezer',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-speed-limit',
            name: 'Speed limit',
            isVisible: true,
            children: [{
              id: 'speed-road',
              name: 'Road',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-extras',
            name: 'Extras',
            isVisible: true,
            children: [{
              id: 'driver-behaviour',
              name: 'Driver Behaviour',
              isVisible: true,
              children: []
            }, {
              id: 'driver-performance',
              name: 'Driver Performance',
              isVisible: true,
              children: []
            }, {
              id: 'dispatch',
              name: 'Dispatch',
              isVisible: true,
              children: []
            }, {
              id: 'taxi',
              name: 'Taxi',
              isVisible: true,
              children: []
            }, {
              id: 'seal',
              name: 'Seal',
              isVisible: true,
              children: []
            }, {
              id: 'relay',
              name: 'Relay',
              isVisible: true,
              children: []
            }, {
              id: 'icons-custom',
              name: 'Custom icons',
              isVisible: true,
              children: []
            }]
          }]
        }, {
          id: 'cng',
          name: 'CNG',
          isVisible: true,
          children: []
        }]
      }, {
        id: 'g-enterprise',
        name: 'Enterprise',
        isVisible: true,
        children: [{
          id: 'e-transport-pro',
          name: 'eTransport',
          isVisible: true,
          children: []
        }, {
          id: 'g-reports',
          name: 'Reports',
          isVisible: true,
          children: [{
            id: 'report-1',
            name: 'FAZ',
            isVisible: true,
            children: []
          }, {
            id: 'report-2',
            name: 'Routes',
            isVisible: true,
            children: []
          }, {
            id: 'report-3',
            name: 'Stationary',
            isVisible: true,
            children: []
          }, {
            id: 'report-5',
            name: 'After hours',
            isVisible: true,
            children: []
          }, {
            id: 'report-6',
            name: 'POI activity',
            isVisible: true,
            children: []
          }, {
            id: 'report-11',
            name: 'Fuel level',
            isVisible: true,
            children: []
          }, {
            id: 'report-12',
            name: 'Engine',
            isVisible: true,
            children: []
          }, {
            id: 'report-14',
            name: 'Countries',
            isVisible: true,
            children: []
          }, {
            id: 'report-16',
            name: 'Costs',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-management',
          name: 'Management',
          isVisible: true,
          children: [{
            id: 'vehicles',
            name: 'Vehicles',
            isVisible: true,
            children: []
          }, {
            id: 'users',
            name: 'Users',
            isVisible: true,
            children: []
          }, {
            id: 'subgroups',
            name: 'Subgroups',
            isVisible: true,
            children: []
          }, {
            id: 'geofences',
            name: 'Geofences',
            isVisible: true,
            children: []
          }, {
            id: 'timetables',
            name: 'Timetables',
            isVisible: true,
            children: []
          }, {
            id: 'integrations',
            name: 'Integrations',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-map',
          name: 'Map',
          isVisible: true,
          children: [{
            id: 'map',
            name: 'Map',
            isVisible: true,
            children: []
          }, {
            id: 'map-routes',
            name: 'Routes',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-notifications',
          name: 'Notifications',
          isVisible: true,
          children: [{
            id: 'notifications',
            name: 'Notifications',
            isVisible: true,
            children: []
          }, {
            id: 'email',
            name: 'Email',
            isVisible: true,
            children: []
          }, {
            id: 'sms',
            name: 'SMS',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-widgets',
          name: 'Widgets',
          isVisible: true,
          children: [{
            id: 'widget-activity',
            name: 'Activity',
            isVisible: true,
            children: []
          }, {
            id: 'widget-live',
            name: 'Live status',
            isVisible: true,
            children: []
          }, {
            id: 'widget-alerts',
            name: 'Alerts',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-sensors',
          name: 'Sensors',
          isVisible: true,
          children: [{
            id: 'can',
            name: 'CAN',
            isVisible: true,
            children: []
          }, {
            id: 'sensor-fuel',
            name: 'Fuel level',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-speed-limit',
          name: 'Speed limit',
          isVisible: true,
          children: [{
            id: 'speed-limit',
            name: 'Setting',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-extras',
          name: 'Extras',
          isVisible: true,
          children: [{
            id: 'driver-behaviour',
            name: 'Driver Behaviour',
            isVisible: true,
            children: []
          }, {
            id: 'driver-performance',
            name: 'Driver Performance',
            isVisible: true,
            children: []
          }]
        }, {
          id: 'g-optional',
          name: 'Optional',
          isVisible: true,
          children: [{
            id: 'g-reports',
            name: 'Reports',
            isVisible: true,
            children: [{
              id: 'report-17',
              name: 'Fleet summary',
              isVisible: true,
              children: []
            }, {
              id: 'report-18',
              name: 'Timesheet',
              isVisible: true,
              children: []
            }, {
              id: 'report-24',
              name: 'Fuel consumption',
              isVisible: true,
              children: []
            }, {
              id: 'report-25',
              name: 'Distance day',
              isVisible: true,
              children: []
            }, {
              id: 'report-100',
              name: 'Detailed stops',
              isVisible: true,
              children: []
            }, {
              id: 'report-102',
              name: 'Detailed route',
              isVisible: true,
              children: []
            }, {
              id: 'report-22',
              name: 'Tachograph files',
              isVisible: true,
              children: []
            }, {
              id: 'report-26',
              name: 'Tachograph timesheet',
              isVisible: true,
              children: []
            }, {
              id: 'report-28',
              name: 'Cabotage',
              isVisible: true,
              children: []
            }, {
              id: 'report-21',
              name: 'OBU Tickets',
              isVisible: true,
              children: []
            }, {
              id: 'report-33',
              name: 'Driver routes',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-map',
            name: 'Map',
            isVisible: true,
            children: [{
              id: 'map-routes',
              name: 'Routes',
              isVisible: true,
              children: []
            }, {
              id: 'map-eta',
              name: 'ETA',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-sensors',
            name: 'Sensors',
            isVisible: true,
            children: [{
              id: 'sensor-door',
              name: 'Door',
              isVisible: true,
              children: []
            }, {
              id: 'mobileye',
              name: 'MobilEye',
              isVisible: true,
              children: []
            }, {
              id: 'freezer',
              name: 'Freezer',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-speed-limit',
            name: 'Speed limit',
            isVisible: true,
            children: [{
              id: 'speed-road',
              name: 'Road',
              isVisible: true,
              children: []
            }]
          }, {
            id: 'g-extras',
            name: 'Extras',
            isVisible: true,
            children: [{
              id: 'dispatch',
              name: 'Dispatch',
              isVisible: true,
              children: []
            }, {
              id: 'tms',
              name: 'TMS',
              isVisible: true,
              children: []
            }, {
              id: 'chat',
              name: 'Chat',
              isVisible: true,
              children: []
            }, {
              id: 'tachograph',
              name: 'Tachograph',
              isVisible: true,
              children: []
            }, {
              id: 'taxi',
              name: 'Taxi',
              isVisible: true,
              children: []
            }, {
              id: 'seal',
              name: 'Seal',
              isVisible: true,
              children: []
            }, {
              id: 'relay',
              name: 'Relay',
              isVisible: true,
              children: []
            }, {
              id: 'icons-custom',
              name: 'Custom icons',
              isVisible: true,
              children: []
            }, {
              id: 'messaging',
              name: 'Messaging',
              isVisible: true,
              children: []
            }, {
              id: 'construction',
              name: 'Construction',
              isVisible: true,
              children: []
            }, {
              id: 'obu',
              name: 'OBU',
              isVisible: true,
              children: []
            }, {
              id: 'rer',
              name: 'RER',
              isVisible: true,
              children: []
            }, {
              id: 'orders',
              name: 'Orders',
              isVisible: true,
              children: []
            }, {
              id: 'maintenance',
              name: 'Maintenance',
              isVisible: true,
              children: []
            }]
          }]
        }, {
          id: 'cng',
          name: 'CNG',
          isVisible: true,
          children: []
        }]
      }]);
    },
    actions: {
      onCheck: function onCheck() {
        var items = _tree.default.getDescendents(this.get('tree'));

        var selected = this.get('selected') || [];
        var checked = [];
        items.forEach(function (node) {
          if (node.children != null && node.children.length > 0 || !node.isChecked) {
            return;
          }

          checked.push(node.id);
        });
        checked.forEach(function (id) {
          var total = items.filterBy('id', id).length;
          var count = checked.filter(function (nodeID) {
            return nodeID === id;
          }).length;
          var wasSelected = selected.includes(id);

          if (wasSelected && count < total) {
            checked = checked.without(id);
          }
        });
        this.set('selected', checked.uniq());
      }
    }
  });

  _exports.default = _default;
});